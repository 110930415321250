import styles from './FeaturesSection.module.scss';
import classNames from '../../../../utils/classNames';

import ModalCaller from '../../../../components/modalCaller/ModalCaller';
import useTranslation from '../../../../hooks/useTranslation';

export const FeaturesSection = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.section}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <h1 className={styles.title}>{t('featuresBlockTitle')}</h1>
                    <ul className={styles.featuresList}>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconGoals)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockGoalsTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockGoalsDescription')}</p>
                        </li>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconQuality)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockQualityTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockQualityDescription')}</p>
                        </li>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconOpenProcess)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockOpenProcessTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockOpenProcessDescription')}</p>
                        </li>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconDiscussion)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockDiscussionTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockDiscussionDescription')}</p>
                        </li>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconFixedBudget)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockFixedBudgetTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockFixedBudgetDescription')}</p>
                        </li>
                        <li className={styles.featuresListItem}>
                            <div className={classNames(styles.featuresListIcon, styles.iconPrice)} />
                            <h2 className={styles.featuresListTitle}>{t('featuresBlockPriceTitle')}</h2>
                            <p className={styles.featuresListDescription}>{t('featuresBlockPriceDescription')}</p>
                        </li>
                    </ul>
                    <ModalCaller textButton={t('featuresBlockStartButton')} />
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;
