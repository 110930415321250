import { Fragment, FunctionComponent } from 'preact';
import OrangeButton from '../orangeButton/OrangeButton';
import handleSmoothScrollTo from '../../utils/handleSmoothScrollTo';

interface Props {
    buttonClass?: any;
    textButton: string;
}

export const ModalCaller: FunctionComponent<Props> = ({ buttonClass, textButton }) => {
    return (
        <Fragment>
            <OrangeButton onClick={() => handleSmoothScrollTo('contacts')} text={textButton} className={buttonClass} />
        </Fragment>
    );
};

export default ModalCaller;
