import { useLocation, useNavigate } from 'react-router';
import { browserLanguage } from '../../i18n';
import { useEffect } from 'preact/compat';

const RedirectToPageWithLang = () => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/${browserLanguage}${pathname}`);
    }, [pathname, navigate]);

    return <></>;
};
export default RedirectToPageWithLang;
