import { FunctionComponent, memo } from 'preact/compat';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import AppRouter from './layouts/AppRouter';
import './App.scss';
import { I18nProvider } from '@lingui/react';
import i18n from './i18n';

const App: FunctionComponent = () => (
    <I18nProvider i18n={i18n}>
        <Provider store={store}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
        </Provider>
    </I18nProvider>
);

export default memo(App);
