import { combineReducers } from 'redux';
import application, { ApplicationState } from './reducers/application';
import counter, { CounterState } from './reducers/counter';

export interface ReduxState {
    application: ApplicationState;
    counter: CounterState;
}

export default combineReducers<ReduxState>({
    application,
    counter
});
