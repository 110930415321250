import { FunctionComponent } from 'preact';
import { PropsWithChildren } from 'preact/compat';

const Page: FunctionComponent<PropsWithChildren> = ({ children }) => {
    // eslint-disable-next-line no-restricted-syntax
    window.scrollTo(0, 0);
    return <>{children}</>;
};

export default Page;
