import styles from './PricesSection.module.scss';

import ModalCaller from '../../../../components/modalCaller/ModalCaller';
import useTranslation from '../../../../hooks/useTranslation';

export const PricesSection = () => {
    const { t } = useTranslation();

    return (
        <section id={'prices'} className={styles.section}>
            <div className={styles.container}>
                <div className={styles.title}>{t('pricesBlockTitle')}</div>
                <div className={styles.priceList}>
                    <div className={styles.card}>
                        <div className={styles.cardTitle}>{t('pricesBlockTariff_1_title')}</div>
                        <div className={styles.cardSubTitle}>{t('pricesBlockTariff_1_subtitle')}</div>
                        <div dir="ltr" className={styles.cardPrice}>
                            {t('pricesBlockTariff_1_price')}
                        </div>
                        <ul className={styles.list}>
                            <li className={styles.listItem}>{t('pricesBlockTariff_1_list_item_1')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_1_list_item_2')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_1_list_item_3')}</li>
                        </ul>
                        <div className={styles.cardButtonWrapper}>
                            <ModalCaller textButton={t('pricesBlockButton_text')} />
                        </div>
                    </div>

                    <div className={styles.card}>
                        <div className={styles.cardTitle}>{t('pricesBlockTariff_2_title')}</div>
                        <div className={styles.cardSubTitle}>{t('pricesBlockTariff_2_subtitle')}</div>
                        <div className={styles.cardPrice}>
                            <span dir="ltr">{t('pricesBlockTariff_2_price')}</span>
                            <span className={styles.month}>{t('pricesBlockTariff_2_price_period')}</span>
                        </div>
                        <ul className={styles.list}>
                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_1')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_2')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_3')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_4')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_5')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_2_list_item_6')}</li>
                        </ul>
                        <div className={styles.cardButtonWrapper}>
                            <ModalCaller textButton={t('pricesBlockButton_text')} />
                        </div>
                    </div>

                    <div className={styles.card}>
                        <div className={styles.cardTitle}>{t('pricesBlockTariff_3_title')}</div>
                        <div className={styles.cardSubTitle}>{t('pricesBlockTariff_3_subtitle')}</div>
                        <div className={styles.cardPrice}>
                            <span dir="ltr">{t('pricesBlockTariff_3_price')}</span>
                            <span className={styles.month}>{t('pricesBlockTariff_3_price_period')}</span>
                        </div>
                        <ul className={styles.list}>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_1')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_2')}</li>

                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_3')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_4')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_5')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_6')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_7')}</li>
                            <li className={styles.listItem}>{t('pricesBlockTariff_3_list_item_8')}</li>
                        </ul>
                        <div className={styles.cardButtonWrapper}>
                            <ModalCaller textButton={t('pricesBlockButton_text')} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PricesSection;
