import { FunctionComponent } from 'preact';
import styles from './OrangeButton.module.scss';
import classNames from '../../utils/classNames';

interface Props {
    onClick();
    text: string;
    className?: any;
}

export const OrangeButton: FunctionComponent<Props> = ({ text, onClick, className }) => {
    return (
        <button
            className={classNames(styles.orangeButton, {
                [className]: className
            })}
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export default OrangeButton;
