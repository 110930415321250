import { useLingui } from '@lingui/react';

const useTranslation = () => {
    const { i18n } = useLingui();
    const { locale } = i18n;

    const t = (id: string) => {
        return i18n._(id);
    };

    return { t, locale };
};

export default useTranslation;
