import { reducerWithInitialState } from 'typescript-fsa-reducers';
import actionCreator from '../utils/actionCreator';

export interface CounterState {
    counterValue: number;
}
const initialState: CounterState = {
    counterValue: 0
};

export const setCounterValue = actionCreator<number>('SET_COUNTER_VALUE');

export default reducerWithInitialState(initialState).case(setCounterValue, (state, nextCounterValue) => ({
    ...state,
    counterValue: nextCounterValue
}));
