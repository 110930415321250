import { ComponentType } from 'preact';
import { ConnectedComponent } from 'react-redux';
import RootPage from '../pages/rootPage/RootPage';
import AboutPage from '../pages/aboutPage/AboutPage';
import RedirectToPageWithLang from '../components/redirectToPageWithLang/RedirectToPageWithLang';

export enum AppRoute {
    ROOT = '/:lang/',
    ROOT_WITHOUT_LANG = '/',
    ABOUT = '/:lang/about',
    ABOUT_WITHOUT_LANG = '/about'
}

interface AppRouteProps {
    path: AppRoute;
    component: ComponentType | ConnectedComponent<any, any>;
}

const routes: AppRouteProps[] = [
    {
        path: AppRoute.ROOT_WITHOUT_LANG,
        component: RedirectToPageWithLang
    },
    {
        path: AppRoute.ABOUT_WITHOUT_LANG,
        component: RedirectToPageWithLang
    },
    {
        path: AppRoute.ABOUT,
        component: AboutPage
    },
    {
        path: AppRoute.ROOT,
        component: RootPage
    }
];

export default routes;
