import { applyMiddleware, createStore as createReduxStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './rootReducer';
import { isGlobalObjectsShared, isReduxLogsEnabled } from '../constants/env';

function getMiddlewares() {
    const middlewares: Middleware[] = [thunk];
    if (isReduxLogsEnabled) {
        middlewares.push(
            createLogger({
                level: 'info'
            })
        );
    }
    return middlewares;
}

const store = createReduxStore(rootReducer, applyMiddleware(...getMiddlewares()));

if (isGlobalObjectsShared) {
    window.store = store;
}

export default store;
