const handleSmoothScrollTo = (id: string) => {
    const block = document.getElementById(id);
    const navbar = document.getElementById('navbar');
    const navbarHeight = navbar == null ? 0 : navbar.offsetHeight;
    if (!block) {
        return;
    }

    // eslint-disable-next-line no-restricted-syntax
    window.scroll({
        behavior: 'smooth',
        top: block.offsetTop - navbarHeight
    });
};

export default handleSmoothScrollTo;
