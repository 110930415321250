import PreactDOM, { PureComponent, RefObject } from 'preact/compat';

let level = 0;

interface Props {
    onClickOutside(event: MouseEvent);
    wrapperRef?: RefObject<HTMLDivElement>;
    className?: string;
    style?: any;
}

interface State {
    closureAvailable?: boolean;
}

export default class ClickOutsideHandler extends PureComponent<Props, State> {
    state: State = {
        closureAvailable: false
    };
    level = ++level;

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);
        document.addEventListener('mousedown', this.handleMouseDown, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
        document.removeEventListener('mousedown', this.handleMouseDown, true);
        level--;
    }

    handleClickOutside = (event) => {
        const { onClickOutside } = this.props;
        const domNode = PreactDOM.findDOMNode(this);
        const { closureAvailable } = this.state;

        if (this.level !== level) {
            return;
        }

        this.setState({
            closureAvailable: false
        });
        if ((!domNode || !domNode.contains(event.target)) && closureAvailable) {
            onClickOutside(event);
        }
    };

    handleMouseDown = (event) => {
        const domNode = PreactDOM.findDOMNode(this);
        if (!domNode || !domNode.contains(event.target)) {
            this.setState({
                closureAvailable: true
            });
        }
    };

    render() {
        const { wrapperRef, children, className, style } = this.props;

        return (
            <div ref={wrapperRef} style={style} className={className}>
                {children}
            </div>
        );
    }
}
