import { FunctionComponent, useEffect, useState } from 'preact/compat';
import classNames from 'classnames';
import styles from './PosterSection.module.scss';
import ModalCaller from '../../../../components/modalCaller/ModalCaller';
import useTranslation from '../../../../hooks/useTranslation';

export const PosterSection: FunctionComponent = () => {
    const { t } = useTranslation();
    const [fixedMenu, setFixedMenu] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        const offset = window.pageYOffset;
        if (offset > 0) {
            setFixedMenu(true);
        } else {
            setFixedMenu(false);
        }
    };

    return (
        <section className={classNames(styles.section, { [styles.fixedMenu]: fixedMenu })}>
            <div className={styles.content}>
                <h1 className={styles.title}>{t('posterSectionTitle')}</h1>

                <p className={styles.description}>{t('posterSectionDescription')}</p>
                <ModalCaller textButton={t('posterSectionButton')} buttonClass={styles.orangeButton} />
            </div>
        </section>
    );
};

export default PosterSection;
