import { FunctionComponent } from 'preact';

import styles from './AboutPage.module.scss';
import Header from '../../components/header/Header';
import useTranslation from '../../hooks/useTranslation';

export const AboutPage: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div>
            <div className={styles.container}>
                <Header scrollSections={[]} isInverted={true} />

                <section className={styles.team}>
                    <h1 className={styles.teamTitle}>{t('aboutPageTitle')}</h1>
                    <p
                        className={styles.teamDescription}
                        dangerouslySetInnerHTML={{ __html: t('aboutPageDescription') }}
                    ></p>
                </section>

                <section className={styles.vacancies}>
                    <h2 className={styles.vacanciesTitle}>{t('aboutPageVacanciesTitle')}</h2>
                    <ul className={styles.vacanciesList}>
                        <li className={styles.vacanciesListItem}>
                            <span className={styles.vacanciesListItemTitle}>
                                Angular Junior разработчика, г. Оренбург
                            </span>
                            <a href="#" className={styles.vacanciesListItemLink}>
                                {t('aboutPageVacanciesShowButton')}
                            </a>
                        </li>

                        <li className={styles.vacanciesListItem}>
                            <span className={styles.vacanciesListItemTitle}>
                                Angular Junior разработчика, г. Оренбург
                            </span>
                            <a href="#" className={styles.vacanciesListItemLink}>
                                {t('aboutPageVacanciesShowButton')}
                            </a>
                        </li>
                    </ul>
                    <p className={styles.vacanciesContacts}>
                        {t('aboutPageVacanciesContacts')} <a href="mailto:info@37bytes.com">info@37bytes.com</a>
                    </p>
                </section>
            </div>
        </div>
    );
};

export default AboutPage;
