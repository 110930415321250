import { PureComponent } from 'preact/compat';
import { Route, Routes } from 'react-router-dom';
import routes from '../constants/routes';
import NotFoundPage from '../pages/notFoundPage/NotFoundPage';
import ErrorBoundary from './ErrorBoundary';
import Page from './Page';
import LanguageLayout from './LanguageLayout';

export default class AppRouter extends PureComponent {
    render() {
        return (
            <Routes>
                {
                    routes.map(({ path, component: Component }) => (
                        <Route
                            element={
                                <LanguageLayout>
                                    <ErrorBoundary>
                                        <Page>
                                            <Component />
                                        </Page>
                                    </ErrorBoundary>
                                </LanguageLayout>
                            }
                            key={path}
                            path={path}
                            exact
                        ></Route>
                    )) as React.ReactNode
                }
                {(<Route element={<NotFoundPage />} />) as React.ReactNode}
            </Routes>
        );
    }
}
