import styles from './AboutSection.module.scss';
import useTranslation from '../../../../hooks/useTranslation';

export const AboutSection = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.about}>
            <div className={styles.aboutContainer}>
                <p className={styles.aboutCompany}>
                    <strong>{t('aboutBlockTitle')}</strong>
                    <span dangerouslySetInnerHTML={{ __html: t('aboutBlockText') }} />
                </p>
            </div>
        </section>
    );
};

export default AboutSection;
