const {
    VITE_IS_GLOBAL_OBJECTS_SHARED,
    VITE_IS_REDUX_LOGS_ENABLED,
    // region переменные из Dockerfile
    VITE_ENV_NAME
    // endregion
} = import.meta.env;

export const isGlobalObjectsShared = VITE_IS_GLOBAL_OBJECTS_SHARED === 'true';
export const isReduxLogsEnabled = VITE_IS_REDUX_LOGS_ENABLED === 'true';

// region переменные из Dockerfile
export const ENV_NAME = import.meta.env.DEV ? 'development' : VITE_ENV_NAME;
// endregion
