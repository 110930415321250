import RU from '../../src/images/flags/rus.svg';
import AR from '../../src/images/flags/ara.svg';
import EN from '../../src/images/flags/eng.svg';

export enum Language {
    RU = 'ru',
    AR = 'ar',
    EN = 'en'
}

export const LANGUAGE_SHORT_FORM = {
    [Language.AR]: 'عربي',
    [Language.EN]: 'eng',
    [Language.RU]: 'рус'
};

export const FLAG_BY_LANGUAGE = {
    [Language.AR]: AR,
    [Language.EN]: EN,
    [Language.RU]: RU
};

export const LANGUAGES = [Language.AR, Language.EN, Language.RU];

export const RTL_LANGUAGES: Array<Language | string> = [Language.AR];

export default Language;
