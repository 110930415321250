export default {
    headerServices: 'خدمات',
    headerProjects: 'ملف',
    headerPrices: 'عودة',
    headerContacts: 'جهات الاتصال',
    headerBackButton: 'السعر',

    posterSectionTitle: 'نصنع أدوات رقمية ونحل المشاكل المعقدة لعملك',
    posterSectionDescription:
        'على مدى السنوات الثلاث الماضية ، تم الانتهاء من أكثر من 70 مشروعًا. دعونا نحل المشاكل المعقدة معا!',
    posterSectionButton: 'ابدأ مشروعًا معنا',

    aboutBlockTitle: 'شركة 37 بايت',
    aboutBlockText:
        '&nbsp;&mdash;ونبسب \nتحتل مكانة قوية في مجال تعهيد تكنولوجيا المعلومات وتنفيذ تطبيقات الأعمال واستشارات تكنولوجيا المعلومات للشركات الصغيرة والمتوسطة والكبيرة.',
    aboutBlockAboutButton: 'معلومات عنا',

    servicesBlockTitle: 'ماذا نفعل',
    servicesBlockWeb: 'تطبيقات الويب\n(B2C ، B2B)',
    servicesBlockMobile: 'تطبيقات الهاتف الجوال\n(iOS ، Android)',
    servicesBlockLegacy: 'يعالج\nالحلول القديمة',
    servicesBlockSupport: 'استغلال\nوالدعم',

    skillsBlockTitle: 'نستطيع',
    skillsList_item_1: 'إجراء الحمل والوحدة وأنواع أخرى <bold> من الاختبارات </bold>',
    skillsList_item_2:
        'واجهات التصميم في <bold> HTML5 </bold> و <bold> CSS3 </bold> و <bold> SASS </bold> (تصميم UI / UX)',
    skillsList_item_3: 'قم بإعداد <bold> التكامل المستمر </bold>',
    skillsList_item_4: 'إنشاء تطبيقات <bold> للجوال والويب عالية التحميل </bold>',
    skillsList_item_5:
        'الاستفادة من مجموعة التقنيات الحديثة: <bold> Java (Spring Boot) </bold> ، <bold> React (React Native) </bold> ، <bold> Vue.js </bold>',
    skillsList_item_6: 'إدارة المشاريع باستخدام <bold> Scrum </bold> و <bold> Kanban </bold>',

    featuresBlockTitle: 'كيف نعمل',
    featuresBlockGoalsTitle: 'الأهداف + المواعيد النهائية',
    featuresBlockGoalsDescription: 'نحدد الأهداف والمواعيد النهائية ، ثم نعمل بمرونة وفقًا لأولويات العميل.',
    featuresBlockQualityTitle: 'جودة',
    featuresBlockQualityDescription:
        'مهمتنا هي تضمين المشروع في المحفظة. كلما كان العمل أفضل ، كان من الأسهل جذب عملاء جدد.',
    featuresBlockOpenProcessTitle: 'اهتمام صريح',
    featuresBlockOpenProcessDescription: 'يشارك العميل في العملية في جميع المراحل. نظهر النتيجة في المراحل المبكرة.',
    featuresBlockDiscussionTitle: 'مناقشة',
    featuresBlockDiscussionDescription:
        'سنشرح ونقترح ونناقش ونطرح الأفكار. لن تسمع "لم يكن في المعارف التقليدية - لن نفعل ذلك".',
    featuresBlockFixedBudgetTitle: 'Fixed-Budget Scope-Controlled',
    featuresBlockFixedBudgetDescription:
        'نحن نعمل على نموذج التحكم في نطاق الميزانية الثابتة. نحن لا نلائم الميزانية - سوف نتفق على التغييرات حتى يرضي كل من العميل والمقاول.',
    featuresBlockPriceTitle: 'التكلفة والدفع',
    featuresBlockPriceDescription:
        'تكلفة المشروع تتشكل بشفافية مطلقة. يتم الدفع بالساعة ، وتظهر النتائج في المظاهرات العادية.',
    featuresBlockStartButton: 'ابدأ مشروعًا',

    projectsBlockTitle: 'أعمالنا وعملائنا',
    projectsBlockSubtitle: 'الألم هو الحب نفسه',
    projectsBlockProject_1_name: 'فستان الشمس - ابحث عن سادة',
    projectsBlockProject_1_shortDescription: 'فستان الشمس - كلمة رقمية مجانية شفهية في عالم الخدمات!',

    statisticBlockProjectsCount: '60+',
    statisticBlockProjectsLabel: 'اكتملت المشاريع',
    statisticBlockClientsCount: '30+',
    statisticBlockClientsLabel: 'زبائن يشعرون بالرضى',
    statisticBlockCoffeeCount: '1204',
    statisticBlockCoffeeLabel: 'أكواب من القهوة في حالة سكر',

    pricesBlockTitle: 'السعر',
    pricesBlockButton_text: 'ابدأ مشروعًا',
    pricesBlockTariff_1_title: 'Basic Project',
    pricesBlockTariff_1_subtitle: 'فترة تطوير تصل إلى 1 شهر',
    pricesBlockTariff_1_price: '$4 000+',
    pricesBlockTariff_1_list_item_1: 'مطور واحد',
    pricesBlockTariff_1_list_item_2: 'تصميم النموذج',
    pricesBlockTariff_1_list_item_3: 'اختبار المشروع اليدوي ',
    pricesBlockTariff_2_title: 'Medium Project',
    pricesBlockTariff_2_subtitle: 'فترة التطوير 1-3 أشهر',
    pricesBlockTariff_2_price: '$8 000+',
    pricesBlockTariff_2_price_period: '/كل شهر',
    pricesBlockTariff_2_list_item_1: 'مطورين',
    pricesBlockTariff_2_list_item_2: 'تصميم فردي',
    pricesBlockTariff_2_list_item_3: 'اختبار متعمق لجودة المشروع',
    pricesBlockTariff_2_list_item_4: 'تنفيذ التحليلات',
    pricesBlockTariff_2_list_item_5: 'مراقبة الخادم',
    pricesBlockTariff_2_list_item_6: 'الدعم الأساسي ',
    pricesBlockTariff_3_title: 'Epic Project',
    pricesBlockTariff_3_subtitle: 'فترة التطوير 4-8 أشهر',
    pricesBlockTariff_3_price: '$16 000+',
    pricesBlockTariff_3_price_period: '/كل شهر',
    pricesBlockTariff_3_list_item_1: '3-5 مطورين ومحللين',
    pricesBlockTariff_3_list_item_2: 'تصميم فردي سريع الاستجابة',
    pricesBlockTariff_3_list_item_3: 'إنشاء نموذج أولي تفاعلي',
    pricesBlockTariff_3_list_item_4: 'اختبار متعمق لجودة المشروع',
    pricesBlockTariff_3_list_item_5: 'تنفيذ Autotest ، التكامل المستمر',
    pricesBlockTariff_3_list_item_6: 'تنفيذ التحليلات ، والتحويل ، ومقاييس KPI',
    pricesBlockTariff_3_list_item_7: 'مراقبة الخادم',
    pricesBlockTariff_3_list_item_8: 'دعم جيش تحرير السودان ',

    contactBlockTitle: 'كيف تتواصل معنا',
    contactBlockPhoneTitle: 'يتصل',
    contactBlockPhone: '+7 (905) 819 82-97',
    contactBlockEmailTitle: 'اكتب',
    contactBlockFormTitle: 'أخبرنا عن مهامك الآن',
    contactBlockFormNameLabel: 'ما اسمك؟',
    contactBlockFormPhoneLabel: 'رقم التليفون',
    contactBlockFormEmailLabel: 'بريد الالكتروني',
    contactBlockFormCommentLabel: 'بضع كلمات عن مشروعك',

    messengersBlockTitle: 'رسل',

    aboutPageTitle: 'فريق 37 bytes',
    aboutPageDescription:
        'شركة 37 بايت - تحتل مكانة قوية في مجال تعهيد تكنولوجيا المعلومات والتنفيذ\n                        تطبيقات الأعمال واستشارات تكنولوجيا المعلومات للشركات الصغيرة والمتوسطة والكبيرة.    الألم نفسه هو الحب\n                        التعب والسمنة. من أجل الحصول على مزيد من المعلومات ، ما هي ممارسات مدرستنا\n                        العمل هو فقط لتحقيق الأهداف منه. الألم هو المسؤول\n                        يريد المتعة لتكون خرقاء من الألم ، لا أحد ينجو من النجاح. إلا أن السود جشعون\n                        لا يرون ، إنهم مخطئون الذين يتركون واجباتهم يهدئون الروح ، وهذا هو الكدح.\n                        <br />\n                        الألم نفسه هو الحب\n                        التعب والسمنة. من أجل الحصول على مزيد من المعلومات ، ما هي ممارسات مدرستنا\n                        العمل هو فقط لتحقيق الأهداف منه. الألم هو المسؤول\n                        يريد المتعة لتكون خرقاء من الألم ، لا أحد ينجو من النجاح. إلا أن السود جشعون\n                        لا يرون ، إنهم مخطئون الذين يتركون واجباتهم يهدئون الروح ، وهذا هو الكدح. \n',
    aboutPageVacanciesTitle: 'نحن نبحث عن الفريق:',
    aboutPageVacanciesContacts: 'للوظائف الشاغرة ، اكتب إلى',
    aboutPageVacanciesShowButton: 'عرض الوظيفة الشاغرة'
};
