import { memo } from 'preact/compat';
import styles from './RootPage.module.scss';
import AboutSection from './sections/aboutSection/AboutSection';
import SpecializationSection from './sections/specializationSection/SpecializationSection';
import PricesSection from './sections/pricesSection/PricesSection';
import ContactsSection from './sections/contactSection/ContactsSection';
import FeaturesSection from './sections/featuresSection/FeaturesSection';
import StatisticSection from './sections/statisticSection/StatisticsSection';
import PosterSection from './sections/posterSection/PosterSection';
import Header from '../../components/header/Header';

const RootPage = () => {
    return (
        <div className={styles.wrapper}>
            <Header />
            <PosterSection />
            {/*<HeaderSection />*/}
            <AboutSection />
            <SpecializationSection />
            <FeaturesSection />
            {/*<ProjectsSection />*/}
            <StatisticSection />
            <PricesSection />
            <ContactsSection />
        </div>
    );
};

export default memo(RootPage);
