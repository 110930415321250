import { FunctionComponent } from 'preact';
import styles from './MessengersBlock.module.scss';
import iconWA from './../../images/messengers/WA_icon.svg';
import iconTG from './../../images/messengers/TG_icon.svg';
import qrWA from './../../images/messengers/WA_QR.svg';
import qrTG from './../../images/messengers/TG_QR.svg';
import useTranslation from '../../hooks/useTranslation';

export const MessengersBlock: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <p className={styles.title}>{t('messengersBlockTitle')}</p>
            <div className={styles.mobile}>
                <a href="https://wa.me/message/B434NF7Q5HOGM1">
                    <img className={styles.icon} src={iconWA} alt={'WhatsApp logo'} />
                </a>
                <a href="https://t.me/llc_37bytes">
                    <img className={styles.icon} src={iconTG} alt={'Telegram logo'} />
                </a>
            </div>
            <div className={styles.desktop}>
                <a href="https://wa.me/message/B434NF7Q5HOGM1">
                    <img className={styles.qr} src={qrWA} alt={'QR-code'} />
                </a>
                <a href="https://t.me/llc_37bytes">
                    <img className={styles.qr} src={qrTG} alt={'QR-code'} />
                </a>
            </div>
        </div>
    );
};

export default MessengersBlock;
