import styles from './StatisticsSection.module.scss';
import useTranslation from '../../../../hooks/useTranslation';

export const StatisticSection = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.section}>
            <div className={styles.list}>
                <div className={styles.listItem}>
                    <div className={styles.listNumber}>{t('statisticBlockProjectsCount')}</div>
                    <div className={styles.listText}>{t('statisticBlockProjectsLabel')}</div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listNumber}>{t('statisticBlockClientsCount')}</div>
                    <div className={styles.listText}>{t('statisticBlockClientsLabel')}</div>
                </div>
                <div className={styles.listItem}>
                    <div className={styles.listNumber}>{t('statisticBlockCoffeeCount')}</div>
                    <div className={styles.listText}>{t('statisticBlockCoffeeLabel')}</div>
                </div>
            </div>

            <div className={styles.image} />
        </section>
    );
};

export default StatisticSection;
