import { matchPath, useLocation } from 'react-router-dom';
import routes from '../constants/routes';

const useMatchPath = () => {
    const { pathname } = useLocation();
    const match = routes.find((route) => matchPath(route, pathname));
    return match ? match.path : '';
};

export default useMatchPath;
