import styles from './SkillsSection.module.scss';
import { Trans } from '@lingui/react';
import useTranslation from '../../../../hooks/useTranslation';

export const SkillsSection = () => {
    const { t } = useTranslation();

    return (
        <section className={styles.skills}>
            <div className={styles.container}>
                <div className={styles.wrapper}>
                    <h1 className={styles.title}>{t('skillsBlockTitle')}</h1>
                    <div className={styles.skillImage} />
                    <ul className={styles.skillList}>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_1'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_2'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_3'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_4'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_5'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                        <li className={styles.skillListItem}>
                            <p className={styles.skillListItemTitle}>
                                <Trans id={'skillsList_item_6'} values={{}} components={{ bold: <strong /> }} />
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default SkillsSection;
