export default {
    headerServices: 'Услуги',
    headerProjects: 'Портфолио',
    headerPrices: 'Стоимость',
    headerContacts: 'Контакты',
    headerBackButton: 'Назад',

    posterSectionTitle: 'Команда талантливых инженеров и менеджеров',
    posterSectionDescription: 'Создаем цифровые инструменты для вашего бизнеса',
    posterSectionButton: 'Начать работу!',

    aboutBlockTitle: 'Компания 37bytes',
    aboutBlockText:
        '&nbsp;&mdash;Мы верим в профессиональный подход к оказанию услуг и стремимся предоставить нашим клиентам решения, которые помогут им достичь своих целей.',
    aboutBlockAboutButton: 'О Нас',

    servicesBlockTitle: 'Что мы делаем',
    servicesBlockWeb: 'Веб-приложения\n(B2C, B2B)',
    servicesBlockMobile: 'Мобильные приложения\n(iOS, Android)',
    servicesBlockLegacy: 'Переработка\nLegacy-решений',
    servicesBlockSupport: 'Эксплуатация\nи поддержка',

    skillsBlockTitle: 'Мы умеем',
    skillsList_item_1: 'Создавать высоконагруженные <bold>мобильные и веб-приложения</bold>',
    skillsList_item_2: 'Проводить нагрузочное, модульное, и другие <bold>виды тестирования</bold>',
    skillsList_item_3:
        'Проектировать интерфейсы на <bold>HTML5</bold>, <bold>CSS3</bold> и <bold>SASS</bold> (UI/UX-дизайн)',
    skillsList_item_4: 'Настраивать <bold>непрерывную интеграцию</bold>',
    skillsList_item_5:
        'Использовать современный стек технологий: <bold>Java (Spring Boot)</bold>,<bold>React (React Native)</bold>, <bold>Vue.js</bold>',
    skillsList_item_6: 'Управлять проектами по <bold>Scrum</bold> и <bold>Kanban</bold>',

    featuresBlockTitle: 'Как мы работаем',
    featuresBlockGoalsTitle: 'Цели + сроки',
    featuresBlockGoalsDescription: 'Фиксируем цели и сроки, затем гибко работаем по приоритетам заказчика.',
    featuresBlockQualityTitle: 'Качество',
    featuresBlockQualityDescription:
        'Наша задача - включить проект в портфолио. Чем качественнее работа, тем проще привлечь новых клиентов.',
    featuresBlockOpenProcessTitle: 'Открытый процесс',
    featuresBlockOpenProcessDescription:
        'Заказчик вовлечен в процесс на всех стадиях. Результат демонстрируем на самых ранних этапах.',

    featuresBlockDiscussionTitle: 'Обсуждение',
    featuresBlockDiscussionDescription:
        'Мы будем объяснять, предлагать, спорить и приносить идеи. Вы не услышите «Не было в ТЗ - делать не будем».',
    featuresBlockFixedBudgetTitle: 'Fixed-Budget Scope-Controlled',
    featuresBlockFixedBudgetDescription:
        'Работаем по модели Fixed-Budget Scope-Controlled. Не вписываемся в бюджет - согласуем изменения, так чтобы довольными остались и Заказчик, и Исполнитель.',
    featuresBlockPriceTitle: 'Стоимость и оплата',
    featuresBlockPriceDescription:
        'Стоимость проекта формируется абсолютно прозрачно. Оплата осуществляется по часам, а результат виден на регулярных демонстрациях.',
    featuresBlockStartButton: 'Начать проект',

    projectsBlockTitle: 'Наши работы и\u00A0клиенты',
    projectsBlockSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod',
    projectsBlockProject_1_name: 'Сарафан - поиск мастеров',
    projectsBlockProject_1_shortDescription: 'Сарафан – бесплатное цифровое сарафанное радио в мире услуг!',

    statisticBlockProjectsCount: '60+',
    statisticBlockProjectsLabel: 'Проектов выполнено',
    statisticBlockClientsCount: '30+',
    statisticBlockClientsLabel: 'Довольных клиентов',
    statisticBlockCoffeeCount: '1204',
    statisticBlockCoffeeLabel: 'Чашек кофе выпито',
    pricesBlockTitle: 'Стоимость',
    pricesBlockButton_text: 'Начать проект',
    pricesBlockTariff_1_title: 'Basic Project',
    pricesBlockTariff_1_subtitle: 'срок разработки до 1-го месяца',
    pricesBlockTariff_1_price: '$4 000+',
    pricesBlockTariff_1_list_item_1: 'Один разработчик',
    pricesBlockTariff_1_list_item_2: 'Шаблонный дизайн',
    pricesBlockTariff_1_list_item_3: 'Ручное тестирование проекта',
    pricesBlockTariff_2_title: 'Medium Project',
    pricesBlockTariff_2_subtitle: 'срок разработки 1-3 месяца',
    pricesBlockTariff_2_price: ' $8 000+',
    pricesBlockTariff_2_price_period: '/в месяц',
    pricesBlockTariff_2_list_item_1: 'Два разработчика',
    pricesBlockTariff_2_list_item_2: 'Индивидуальный дизайн',
    pricesBlockTariff_2_list_item_3: 'Углубленное тестирование качества проекта',
    pricesBlockTariff_2_list_item_4: 'Внедрение аналитики',
    pricesBlockTariff_2_list_item_5: 'Мониторинг серверов',
    pricesBlockTariff_2_list_item_6: 'Базовая поддержка',
    pricesBlockTariff_3_title: 'Epic Project',
    pricesBlockTariff_3_subtitle: 'срок разработки 4-8 месяцев',
    pricesBlockTariff_3_price: '$16 000+',
    pricesBlockTariff_3_price_period: '/в месяц',
    pricesBlockTariff_3_list_item_1: '3-5 разработчиков и аналитик',
    pricesBlockTariff_3_list_item_2: 'Индивидуальный, адаптивный дизайн',
    pricesBlockTariff_3_list_item_3: 'Создание интерактивного прототипа',
    pricesBlockTariff_3_list_item_4: 'Углубленное тестирование качества проекта',
    pricesBlockTariff_3_list_item_5: 'Внедрение автотестов, непрерывная интеграция',
    pricesBlockTariff_3_list_item_6: 'Внедрение аналитики, конверсии, KPI метрик',
    pricesBlockTariff_3_list_item_7: 'Мониторинг серверов',
    pricesBlockTariff_3_list_item_8: 'Поддержка на условиях SLA',

    contactBlockTitle: 'Как связаться с нами',
    contactBlockPhoneTitle: 'Звоните',
    contactBlockPhone: '+7 (499) 348 86-07',
    contactBlockEmailTitle: 'Пишите',
    contactBlockFormTitle: 'Расскажите о своих задачах нам прямо сейчас',
    contactBlockFormNameLabel: 'Как Вас зовут?',
    contactBlockFormPhoneLabel: 'Номер телефона',
    contactBlockFormEmailLabel: 'Email',
    contactBlockFormCommentLabel: 'Пару слов о вашем проекте',

    messengersBlockTitle: 'Мессенджеры',

    aboutPageTitle: 'Команда 37 bytes',
    aboutPageDescription:
        'Компания 37 bytes - занимает сильную позицию в области ИТ-аутсорсинга, внедрения\n                        бизнес-приложений и ИТ-консалтинга для малых, средних и больших компаний. <br />\n                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco\n                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in\n                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat\n                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n                        <br />\n                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut\n                        labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco\n                        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in\n                        voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat\n                        non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    aboutPageVacanciesTitle: 'Ищем в команду:',
    aboutPageVacanciesContacts: 'По вопросам вакансий пишите на ',
    aboutPageVacanciesShowButton: 'Посмотреть вакансию'
};
