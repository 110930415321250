import { FunctionComponent, useCallback, useEffect, useState } from 'preact/compat';
import { NavLink } from 'react-router-dom';

import styles from './Header.module.scss';
import LocaleSwitch from '../localeSwitch/LocaleSwitch';
import classNames from '../../utils/classNames';
import handleSmoothScrollTo from '../../utils/handleSmoothScrollTo';
import Logo from '../../images/white-logo.svg';
import { useLingui } from '@lingui/react';
import useTranslation from '../../hooks/useTranslation';

interface Props {
    scrollSections?: any[];
    isInverted?: boolean;
}

export const Header: FunctionComponent<Props> = ({ isInverted }) => {
    const {
        i18n: { locale }
    } = useLingui();
    const { t } = useTranslation();
    const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
    const [scrolled, setScrolled] = useState<boolean>(false);

    const handleWidth = () => {
        if (window.innerWidth > 1200) {
            document.body.style.overflowY = 'auto';
            setMenuOpen(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleWidth);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleWidth);
        };
    }, []);

    const handleScroll = () => {
        const offset = window.pageYOffset;
        if (offset > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    };

    const handleToggleMenu = useCallback(() => {
        document.body.style.overflowY = isMenuOpen ? 'auto' : 'hidden';

        setMenuOpen(!isMenuOpen);
    }, [isMenuOpen]);

    const handleSmoothScrollToWithToggleMenu = (id) => {
        if (window.innerWidth < 1200) {
            handleToggleMenu();
        }
        handleSmoothScrollTo(id);
    };

    const handleScrollOnHeader = () => {
        // eslint-disable-next-line no-restricted-syntax
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <header>
            <div
                className={classNames(styles.navbar, {
                    [styles.scrolled]: scrolled,
                    [styles.whiteHeader]: isInverted,
                    [styles.fixed]: scrolled
                })}
                id="navbar"
            >
                <div className={classNames(styles.container)}>
                    <div className={styles.navbarContent}>
                        <div className={styles.navbarLogoWrapper}>
                            {isInverted ? (
                                <NavLink to={`/${locale}/`} className={styles.navbarLogo} />
                            ) : (
                                <img src={Logo} className={styles.navbarLogo} onClick={handleScrollOnHeader} alt={''} />
                            )}
                        </div>
                        <div className={styles.navBarAndLangSwitchWrapper}>
                            <button
                                onClick={handleToggleMenu}
                                className={classNames(styles.buttonBurger, { [styles.active]: isMenuOpen })}
                            >
                                <span className={styles.line} />
                                <span className={styles.line} />
                                <span className={styles.line} />
                            </button>
                            <div className={classNames(styles.navbarListWrapper, { [styles.active]: isMenuOpen })}>
                                <nav className={styles.navbarList}>
                                    <a
                                        onClick={() => handleSmoothScrollToWithToggleMenu('services')}
                                        className={styles.navbarItem}
                                    >
                                        {t('headerServices')}
                                    </a>

                                    {/*<a*/}
                                    {/*    onClick={() => handleSmoothScrollToWithToggleMenu('projects')}*/}
                                    {/*    className={styles.navbarItem}*/}
                                    {/*>*/}
                                    {/*    {t('headerProjects')}*/}
                                    {/*</a>*/}

                                    <a
                                        onClick={() => handleSmoothScrollToWithToggleMenu('prices')}
                                        className={styles.navbarItem}
                                    >
                                        {t('headerPrices')}
                                    </a>

                                    <a
                                        onClick={() => handleSmoothScrollToWithToggleMenu('contacts')}
                                        className={styles.navbarItem}
                                    >
                                        {t('headerContacts')}
                                    </a>
                                </nav>
                            </div>
                            <LocaleSwitch inverted={isInverted} />
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
