export default {
    headerServices: 'Services',
    headerProjects: 'Projects',
    headerPrices: 'Pricing',
    headerContacts: 'Contacts',
    headerBackButton: 'Back',

    posterSectionTitle: 'Top talented software engineers and managers across the globe',
    posterSectionDescription: 'developing and maintaining the digital tools for your business',
    posterSectionButton: "LET'S WORK!",

    aboutBlockTitle: '37bytes company',
    aboutBlockText:
        '&nbsp;&mdash;We believe in professional services approach and we strive to deliver the solutions to our customers to help them achieve their goals in the best way ever.',
    aboutBlockAboutButton: 'About',

    servicesBlockTitle: 'Services',
    servicesBlockWeb: 'Web applications\n(B2C, B2B)',
    servicesBlockMobile: 'Mobile applications\n(iOS, Android)',
    servicesBlockLegacy: 'Legacy solutions\nrefactoring',
    servicesBlockSupport: 'Operations\nand support',

    skillsBlockTitle: 'Our skills',
    skillsList_item_1: 'Create high-performance <bold>mobile and web applications</bold>',
    skillsList_item_2: 'Perform load-testing, unit-testing and <bold>other types of testing</bold>',
    skillsList_item_3:
        'Create interfaces with <bold>HTML5</bold>, <bold>CSS3</bold> and <bold>SASS</bold> (UI/UX-design)',
    skillsList_item_4: 'Deploy <bold>continuous integration</bold>',
    skillsList_item_5:
        'Modern stack of technologies: <bold>Java (Spring Boot)</bold>, <bold>React (React Native)</bold>, <bold>Vue.js</bold>',
    skillsList_item_6: 'Manage projects with <bold>Scrum</bold> and <bold>Kanban</bold>',

    featuresBlockTitle: 'How we work',
    featuresBlockGoalsTitle: 'Goals + deadlines',
    featuresBlockGoalsDescription:
        "We are fixing goals and deadlines, then working flexibly according to the customer's priorities.",
    featuresBlockQualityTitle: 'Quality',
    featuresBlockQualityDescription:
        'Our goal is to include the project in the portfolio. The higher the quality of the work, the easier it is to attract new clients.',
    featuresBlockOpenProcessTitle: 'Open process',
    featuresBlockOpenProcessDescription:
        'The customer is involved in the process at all stages. We demonstrate the result at the earliest stages.',
    featuresBlockDiscussionTitle: 'Discussion',
    featuresBlockDiscussionDescription:
        "We will explain, suggest, argue, and bring ideas. You won't hear, \"It wasn't in the contract, we won't do it.",
    featuresBlockFixedBudgetTitle: 'Fixed-Budget Scope-Controlled',
    featuresBlockFixedBudgetDescription:
        'We work with a Fixed-Budget Scope-Controlled model. If we do not fit into the budget - we negotiate on changes, until both the Customer and the Contractor are satisfied.',
    featuresBlockPriceTitle: 'Costs and payment',
    featuresBlockPriceDescription:
        'The cost of the project is absolutely transparent. Payment is made on hourly rate and the result is seen at the regular demos.',
    featuresBlockStartButton: "LET'S WORK!",

    projectsBlockTitle: 'eng|text',
    projectsBlockSubtitle: 'eng|text',
    projectsBlockProject_1_name: 'eng|text',
    projectsBlockProject_1_shortDescription: 'eng|text',

    statisticBlockProjectsCount: '210+',
    statisticBlockProjectsLabel: 'Projects finished',
    statisticBlockClientsCount: '104+',
    statisticBlockClientsLabel: 'Happy clients',
    statisticBlockCoffeeCount: '1204',
    statisticBlockCoffeeLabel: 'Cups of coffee drank',

    pricesBlockTitle: 'Prices',
    pricesBlockButton_text: "LET'S WORK!",
    pricesBlockTariff_1_title: 'Basic Project',
    pricesBlockTariff_1_subtitle: 'duration up to 1 month',
    pricesBlockTariff_1_price: '$4 000+',
    pricesBlockTariff_1_list_item_1: 'Single developer',
    pricesBlockTariff_1_list_item_2: 'Template design',
    pricesBlockTariff_1_list_item_3: 'Manual testing',
    pricesBlockTariff_2_title: 'Medium Project',
    pricesBlockTariff_2_subtitle: 'duration 1-3 months',
    pricesBlockTariff_2_price: ' $8 000+',
    pricesBlockTariff_2_price_period: '/per month',
    pricesBlockTariff_2_list_item_1: 'Two developers',
    pricesBlockTariff_2_list_item_2: 'Individual design',
    pricesBlockTariff_2_list_item_3: 'In-depth quality testing',
    pricesBlockTariff_2_list_item_4: 'Analytics',
    pricesBlockTariff_2_list_item_5: 'Monitoring',
    pricesBlockTariff_2_list_item_6: 'Limited support',
    pricesBlockTariff_3_title: 'Epic Project',
    pricesBlockTariff_3_subtitle: 'duration 4-8 months',
    pricesBlockTariff_3_price: '$16 000+',
    pricesBlockTariff_3_price_period: '/per month',
    pricesBlockTariff_3_list_item_1: '3-5 developers and analyst',
    pricesBlockTariff_3_list_item_2: 'Individual, adaptive design',
    pricesBlockTariff_3_list_item_3: 'Creating an interactive prototype',
    pricesBlockTariff_3_list_item_4: 'In-depth quality testing',
    pricesBlockTariff_3_list_item_5: 'Test automation, continuous integration',
    pricesBlockTariff_3_list_item_6: 'Analytics, conversion, KPI metrics',
    pricesBlockTariff_3_list_item_7: 'Monitoring',
    pricesBlockTariff_3_list_item_8: 'SLA-based support',

    contactBlockTitle: 'Contact us',
    contactBlockPhoneTitle: 'Call',
    contactBlockPhone: '+971 (585) 416-088',
    contactBlockEmailTitle: 'Mail',
    contactBlockFormTitle: 'eng|text',
    contactBlockFormNameLabel: 'eng|text',
    contactBlockFormPhoneLabel: 'eng|text',
    contactBlockFormEmailLabel: 'eng|text',
    contactBlockFormCommentLabel: 'eng|text',

    messengersBlockTitle: 'Messengers',

    aboutPageTitle: 'eng|text',
    aboutPageDescription: 'eng|text',
    aboutPageVacanciesTitle: 'eng|text',
    aboutPageVacanciesContacts: 'eng|text',
    aboutPageVacanciesShowButton: 'eng|text'
};
