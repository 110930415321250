import ServicesSection from '../servicesSection/ServicesSection';
import SkillsSection from '../skillsSection/SkillsSection';

import styles from './SpecializationSection.module.scss';

export const SpecializationSection = () => {
    return (
        <div className={styles.specialization}>
            <ServicesSection />
            <SkillsSection />
        </div>
    );
};

export default SpecializationSection;
