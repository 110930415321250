import { FunctionComponent, PropsWithChildren, useEffect } from 'preact/compat';
import { generatePath, useNavigate, useParams } from 'react-router';
import Language, { LANGUAGES } from '../constants/language';
import { activate, browserLanguage } from '../i18n';
import { useLingui } from '@lingui/react';
import useMatchPath from '../hooks/useMatchPath';

const LanguageLayout: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();
    const {
        i18n: { locale }
    } = useLingui();
    const path = useMatchPath();

    const params = useParams<{ lang?: string }>();

    useEffect(() => {
        const languageFromParams = params.lang as Language;
        const normalizedLanguageFromParams = LANGUAGES.includes(languageFromParams)
            ? languageFromParams
            : browserLanguage;

        console.log('LocaleSwitch.useEffect', { languageFromParams, 'i18n.locale': locale });

        if (languageFromParams && languageFromParams !== normalizedLanguageFromParams) {
            console.log(`switching from ${locale} to ${normalizedLanguageFromParams}...`);
            activate(normalizedLanguageFromParams).then();
            navigate(generatePath(path, { ...params, lang: normalizedLanguageFromParams }), { replace: true });
            return;
        }

        if (normalizedLanguageFromParams && normalizedLanguageFromParams !== locale) {
            console.log(`switching from ${locale} to ${normalizedLanguageFromParams}...`);
            activate(normalizedLanguageFromParams).then();
            navigate(generatePath(path, { ...params, lang: normalizedLanguageFromParams }), { replace: true });
        }
    }, [params, navigate, locale, path]);

    return <>{children}</>;
};

export default LanguageLayout;
