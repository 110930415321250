import { FunctionComponent } from 'preact';
import styles from './ContactsBlock.module.scss';

import MessengersBlock from '../messengersBlock/MessengersBlock';
import useTranslation from '../../hooks/useTranslation';

export const ContactBlock: FunctionComponent = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.container}>
            <h1 className={styles.title}>{t('contactBlockTitle')}</h1>
            <div className={styles.blocks}>
                <div className={styles.block}>
                    <p className={styles.blockTitle}>{t('contactBlockPhoneTitle')}</p>
                    <a
                        dir="ltr"
                        href={`tel:${t('contactBlockPhone')}`}
                        className={styles.value}
                        dangerouslySetInnerHTML={{ __html: t('contactBlockPhone') }}
                    />
                </div>
                <div className={styles.block}>
                    <p className={styles.blockTitle}>{t('contactBlockEmailTitle')}</p>
                    <a href="mailto:info@37bytes.com" className={styles.value}>
                        info@37bytes.com
                    </a>
                </div>
            </div>
            <MessengersBlock />
        </div>
    );
};

export default ContactBlock;
