import { i18n } from '@lingui/core';
import { en, ar, ru } from 'make-plural/plurals';
import ruText from './locales/ru';
import arText from './locales/ar';
import enText from './locales/en';
import Language, { LANGUAGES } from './constants/language';
import { isRTL } from './utils/isRTL';

const resources = {
    [Language.RU]: ruText,
    [Language.AR]: arText,
    [Language.EN]: enText
};

export let browserLanguage = window.navigator.language.split('-')[0];
if (!LANGUAGES.includes(browserLanguage as Language)) {
    browserLanguage = document.documentElement.getAttribute('lang') || Language.EN;
}

// @ts-ignore
// i18n.load(resources);

i18n.loadLocaleData(Language.EN, { plurals: en });
i18n.loadLocaleData(Language.AR, { plurals: ar });
i18n.loadLocaleData(Language.RU, { plurals: ru });

export const activate = async (locale) => {
    await i18n.load(locale, resources[locale]);
    i18n.activate(locale);
    browserLanguage = locale;
    document.documentElement.setAttribute('lang', locale);
    document.documentElement.setAttribute('dir', isRTL(locale) ? 'rtl' : 'ltr');
};

activate(browserLanguage);

export default i18n;
