import styles from './ServicesSection.module.scss';
import classNames from '../../../../utils/classNames';
import useTranslation from '../../../../hooks/useTranslation';

export const ServicesSection = () => {
    const { t } = useTranslation();
    return (
        <section className={styles.wrapper} id={'services'}>
            <div className={styles.container}>
                <h1 className={styles.title}>{t('servicesBlockTitle')}</h1>
                <ul className={styles.serviceList}>
                    <li className={classNames(styles.serviceItem, styles.serviceItemWeb)}>
                        <p className={styles.serviceLitItemTitle}>{t('servicesBlockWeb')}</p>
                    </li>
                    <li className={classNames(styles.serviceItem, styles.serviceItemMobile)}>
                        <p className={styles.serviceLitItemTitle}>{t('servicesBlockMobile')}</p>
                    </li>
                    <li className={classNames(styles.serviceItem, styles.serviceItemLegacy)}>
                        <p className={styles.serviceLitItemTitle}>{t('servicesBlockLegacy')}</p>
                    </li>
                    <li className={classNames(styles.serviceItem, styles.serviceItemSupport)}>
                        <p className={styles.serviceLitItemTitle}>{t('servicesBlockSupport')}</p>
                    </li>
                </ul>
            </div>
        </section>
    );
};

export default ServicesSection;
