import { FunctionComponent, useCallback, useRef, useState } from 'preact/compat';

import ClickOutsideHandler from '../clickOutsideHandler/ClickOutsideHandler';
import styles from './LocaleSwitch.module.scss';
import { FLAG_BY_LANGUAGE, LANGUAGE_SHORT_FORM, LANGUAGES } from '../../constants/language';
import classNames from '../../utils/classNames';
import { generatePath, useNavigate, useParams } from 'react-router';
import { useLingui } from '@lingui/react';
import useMatchPath from '../../hooks/useMatchPath';

interface Props {
    inverted?: boolean;
}

export const LocaleSwitch: FunctionComponent<Props> = ({ inverted }) => {
    const navigate = useNavigate();
    const {
        i18n: { locale: currentLanguage }
    } = useLingui();
    const path = useMatchPath();
    const [isVisibleLanguageList, setVisibleLanguageList] = useState<boolean>(false);

    const currentLanguageButtonRef = useRef<HTMLButtonElement>(null);
    const params = useParams<any>();

    const changeLanguage = (lang) => () => {
        // eslint-disable-next-line no-restricted-syntax
        window.scrollTo(0, 0);
        console.log('changeLanguage', lang, path, params);
        navigate(generatePath(path, { ...params, lang }), { replace: true });
    };

    const handleToggleLanguageList = () => setVisibleLanguageList(!isVisibleLanguageList);

    const handleCloseLanguageList = useCallback((event) => {
        if (currentLanguageButtonRef.current === event.target) {
            return;
        }
        setVisibleLanguageList(false);
    }, []);

    return (
        <div
            className={classNames(styles.wrapper, {
                [styles.inverted]: inverted
            })}
        >
            <button
                ref={currentLanguageButtonRef}
                onClick={handleToggleLanguageList}
                className={styles.buttonLanguage}
                style={{
                    backgroundImage: `url(${FLAG_BY_LANGUAGE[currentLanguage]})`
                }}
            >
                {LANGUAGE_SHORT_FORM[currentLanguage]}
            </button>
            {isVisibleLanguageList ? (
                <ClickOutsideHandler className={styles.listWrapper} onClickOutside={handleCloseLanguageList}>
                    {LANGUAGES.filter((lang) => lang !== currentLanguage).map((language) => {
                        return (
                            <button
                                key={language}
                                className={styles.langButton}
                                style={{
                                    backgroundImage: `url(${FLAG_BY_LANGUAGE[language]})`
                                }}
                                onClick={changeLanguage(language)}
                            >
                                {LANGUAGE_SHORT_FORM[language]}
                            </button>
                        );
                    })}
                </ClickOutsideHandler>
            ) : null}
        </div>
    );
};

export default LocaleSwitch;
