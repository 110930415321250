import { FunctionComponent } from 'preact';
import ContactBlock from '../../../../components/contactBlock/ContactBlock';
import styles from './ContactSection.module.scss';

export const ContactsSection: FunctionComponent = () => {
    return (
        <section id="contacts" className={styles.section}>
            <ContactBlock />
        </section>
    );
};

export default ContactsSection;
